import Vue from 'vue'

Vue.filter('relation_name_translate', function(value) {
    
    switch(value) {

        case "rooms":
            return "Stanze"

        case "chairs":
            return "Poltrone"   
            
        case "duration":
            return "Durata"                

        case "user":
            return "Utenti software"

        case "patient_pathology":
            return "Patologie assegnate"

    }

    return value

})