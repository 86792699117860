<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div v-show="chairs.DB.length < 1" class="alert alert-warning m-0">
                        Ancora nessuna poltrona
                    </div>

                    <div class="row">
                        <div v-for="chair of chairs.DB" :key="chair.id" div class="col-lg-4">
                            <div class="card card-body mb-2">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p class="text-uppercase font-weight-bold m-0">
                                            {{chair.title}} n. {{chair.number}}
                                        </p>
                                        <p>
                                            <small>
                                                {{chair.description ? chair.description : `Nessuna descrizione`}}
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-md-5 text-right d-flex flex-column justify-content-between">

                                        <div v-if="chair.state" class="badge badge-success mb-1">Visibile</div>
                                        <div v-else class="badge badge-warning mb-1">Non visibile</div>

                                        <router-link tag="button" :to="`/user/poltrone/modifica/${chair.title}/${chair.number}`" class="btn btn-ifo btn-sm" :disabled="functionDisabled(14, 'read')">
                                            Gestisci
                                        </router-link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" to="/user/poltrone/aggiungi" v-bind:class="{'disabled': functionDisabled(14, 'create')}">
                            <span class="d-none d-md-block">Aggiungi poltrona</span> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" to="/user/poltrone/duration" v-bind:class="{'disabled': functionDisabled(14, 'read')}">
                            <span class="d-none d-md-block">Durata</span> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
                            </svg>
                        </router-link>
                    </li>                   
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import store  from '@/store'

    export default {
        data() {
            return {
                chairs: {
                    DB: []
                },
                loaders: {
                    reading: true
                }
            }
        },
        methods: {
            async readChairs() {

                try {

                    const chairs = await this.axios.get(`user/chairs/read/all`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.chairs.DB = chairs.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readChairs()
        }
    }

</script>