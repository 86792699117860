<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-md-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <p class="font-weight-bold m-0">Paziente</p>

                    <p class="text-muted mb-3">
                        <small>
                            Ricerca il paziente compilando uno dei 3 campi che seguono
                        </small>
                    </p>

                    <form @submit.prevent="createAppointment" autocomplete="off">

                        <div class="row">
                            
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="surname">Cognome</label>
                                    <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.user.surname.$model" v-bind:class="{'is-invalid': (!$v.form.user.surname.minLength && $v.form.user.surname.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">

                                    <p v-if="!$v.form.user.surname.minLength && $v.form.user.surname.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>
                                    <p v-if="validation.ko_search_patient" class="m-0 text-danger">
                                        <small>{{validation.ko_search_patient}}</small>
                                    </p>
                                    <p v-if="validation.ok_search_patient" class="m-0 text-success">
                                        <small>{{validation.ok_search_patient}}</small>
                                    </p>

                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.user.name.$model" v-bind:class="{'is-invalid': (!$v.form.user.name.minLength && $v.form.user.name.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">
                                    
                                    <p v-if="!$v.form.user.name.minLength && $v.form.user.name.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="CF">Codice fiscale</label>
                                    <input type="text" class="form-control text-uppercase f-family-monospace font-weight-bold" id="CF" v-model.trim="$v.form.user.CF.$model" v-bind:class="{'is-invalid': (!$v.form.user.CF.minLength && $v.form.user.CF.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">

                                    <p v-if="!$v.form.user.CF.minLength && $v.form.user.CF.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>

                                </div>
                            </div>
                            
                        </div>

                        <p class="m-0" v-show="loaders.reading.patients">
                            <small class="text-muted">
                                Ricerca pazienti..
                            </small>
                        </p>

                        <div v-if="patients.length > 0" class="table-responsive">
                            <table class="table_AO">
                                <thead>
                                    <tr>
                                        <th>
                                            Cognome
                                        </th>
                                        <th>
                                            Nome
                                        </th>
                                        <th>
                                            Codice fiscale
                                        </th>
                                        <th class="text-center">
                                            Patologie
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="patient of patients" :key="`patient_${patient.id}`" @click="update_form_user_new(patient)">
                                        <td>
                                            {{patient.surname}}
                                        </td>
                                        <td>
                                            {{patient.name}}
                                        </td>
                                        <td class="f-family-monospace font-weight-bold">
                                            {{patient.CF}}
                                        </td>
                                        <td class="f-family-monospace text-center font-weight-bold">
                                            {{patient.patients_pathologies.length}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Campo note paziente -->
                        <div class="form-group mt-2" v-if="patients_pathologies.length > 0">
                            <label for="note">Note</label>
                            <textarea type="text" class="form-control" id="surnotename" :value="form.user.note" placeholder="Note sul paziente ricercato.." disabled></textarea>
                        </div>

                        <b-tabs content-class="border border-top-0 mb-3" v-if="patients_pathologies.length > 0">
                            <b-tab title="Patologie associate al paziente" active>
                                <div class="container-fluid p-3">

                                    <div v-if="patients_pathologies.length < 1" class="alert alert-warning m-0">
                                        Ancora nessuna patologia associata
                                    </div>

                                    <div v-else class="row">
                                        <div v-for="patient_pathology of patients_pathologies" :key="patient_pathology.id" div class="col-lg-4">
                                            <div class="card card-tab card-body mb-2">
                                                <p class="text-uppercase m-0 font-weight-bold">
                                                    Patologia
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.pathology.title}}
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold">
                                                    Terapia
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.id_therapy ? patient_pathology.therapy.title : "Nessuna terapia"}}
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                    Durata appuntamento
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.sessions_duration}} minuti
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                    Appuntamenti creati in precedenza
                                                </p>
                                                <p class="m-0 text-truncate" v-if="patient_pathology.appointment.length > 0">
                                                    {{patient_pathology.appointment.length}} appuntamenti
                                                </p>
                                                <p class="m-0 text-truncate text-uppercase text-success" v-else>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-bookmark-star" viewBox="0 0 16 16">
                                                        <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z"/>
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                                    </svg>
                                                    
                                                    <strong>
                                                        Primo appuntamento
                                                    </strong>
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </b-tab>
                        </b-tabs>

                        <div class="row">
                            <div class="col-lg-6 border-right-lg">

                                <div v-if="patients_pathologies.length > 0">

                                    <p class="font-weight-bold m-0">Informazioni visita</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Riepilogo sulle informazioni della visita
                                        </small>
                                    </p>

                                    <div class="form-group">

                                        <label for="pathologies">Seleziona una patologia</label>

                                        <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" @input="update_therapies($event.target.value)">
                                            <option value="" selected disabled>
                                                Seleziona una patologia
                                            </option>
                                            <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                {{patient_pathology.pathology.title}}
                                            </option>
                                        </select>

                                        <small class="form-text text-muted">
                                            Seleziona la patologia per l'appuntamento
                                        </small>

                                    </div>

                                </div>

                                <div v-show="form.appointment.id_patient_pathology">
                                    <div class="form-group">

                                        <label for="visit_types">Seleziona tipo visita</label>

                                        <select class="form-control" id="visit_types" v-on:change="readVisitType($event.target.value)" v-model="form.appointment.id_visit_type" aria-describedby="room_help">
                                            <option value="0" selected>
                                                Seleziona tipo visita
                                            </option>
                                            <option v-for="visit_type of visit_types.DB" :key="`room_${visit_type.id}`" :value="visit_type.id">
                                                 {{visit_type.title}} - ({{visit_type.duration}} minuti)
                                            </option>
                                        </select>

                                        <p id="visit_type_help" class="form-text text-muted mb-0">
                                            <small>
                                                Seleziona il tipo di visita
                                            </small>
                                        </p>

                                    </div>


                                    <div class="form-group">

                                        <label for="rooms">Seleziona una stanza</label>

                                        <select class="form-control" id="rooms" v-on:change="readRoom($event.target.value)" v-model="form.appointment.id_room" aria-describedby="room_help">
                                            <option value="" selected disabled>
                                                Seleziona una stanza
                                            </option>
                                            <option v-for="room of rooms.DB" :key="`room_${room.id}`" :value="room.id">
                                                {{room.number}} - {{room.title}}
                                            </option>
                                        </select>

                                        <p id="room_help" class="form-text text-muted mb-0">
                                            <small>
                                                Seleziona la stanza per l'appuntamento.
                                            </small>
                                        </p>

                                    </div>

                                    <div class="form-group" v-if="this.functionSpecialEnabled(4)" v-show="form.appointment.id_room">

                                        <div class="custom-control custom-checkbox text-truncate">
                                            <input type="checkbox" class="custom-control-input" id="urgent" v-model="form.appointment.urgent" @change="set_params_for_appointment_urgent()">
                                            <label class="custom-control-label font-weight-bold text-danger text-underline" for="urgent"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-exclamation-octagon mr-1" viewBox="0 0 16 16">
                                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                            </svg> <u>Appuntamento urgente</u></label>
                                        </div>

                                        <small class="form-text text-muted">
                                            Hai la possibilità di creare un appuntamento urgente impostando <b>data</b> ed <b>orario</b> in <b>questo momento</b>! Non verrano prese in considerazione le notifiche per il paziente ed il programma teraupetico.
                                        </small>

                                    </div>

                                    <button class="btn btn-ifo" :disabled="loaders.creating" v-if="form.appointment.urgent">
                                    
                                        <span v-show="!loaders.creating">
                                            Aggiungi
                                        </span>

                                        <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                </div>

                            </div>
                            <div class="col-lg-6" v-show="form.appointment.id_patient_pathology">
                                <div v-if="therapies_by_pathology.length > 0">

                                    <p class="font-weight-bold m-0">Storico terapie</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Cronologia delle terapie associate al paziente
                                        </small>
                                    </p>

                                    <div class="row font-weight-bold">
                                        <div class="col-3">
                                            <p class="m-0 f-size-0_8-em border-bottom">
                                                Data
                                            </p>
                                        </div>
                                        <div class="col-4">
                                            <p class="m-0 f-size-0_8-em border-bottom">
                                                Vecchia terapia
                                            </p>
                                        </div>
                                        <!--<div class="col-3">
                                            <p class="m-0 f-size-0_8-em border-bottom">
                                                Motivazione
                                            </p>
                                        </div>-->
                                        <div class="col-3">
                                            <p class="m-0 f-size-0_8-em border-bottom">
                                                Utente
                                            </p>
                                        </div>
                                        <div class="col-1"></div>
                                        <div class="col-1"></div>
                                    </div>

                                    <p v-if="therapy_cronology.length < 1">
                                        <small class="text-warning font-weight-bold">
                                            Cronologia assente..
                                        </small>
                                    </p>

                                    <div class="row" v-for="therapy_cronology__ of therapy_cronology" :key="`therapy_cronology_${therapy_cronology__.id}`">
                                        <div class="col-3">
                                            <p class="m-0 f-size-0_8-em f-family-monospace">
                                                {{therapy_cronology__.date | data_slash}}
                                            </p>
                                        </div>
                                        <div class="col-4">
                                            <p class="m-0 f-size-0_8-em text-truncate">
                                                {{therapy_cronology__.title_old_therapy ? therapy_cronology__.title_old_therapy : "Nessuna terapia"}}
                                            </p>
                                        </div>
                                        <!--<div class="col-3">
                                            <p class="m-0 f-size-0_8-em text-truncate">
                                                {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                            </p>
                                        </div>-->
                                        <div class="col-3">
                                            <p class="m-0 f-size-0_8-em text-truncate">
                                                {{therapy_cronology__.modifier_user}}
                                            </p>
                                        </div>
                                        <div class="col-1">
                                            <v-btn :id="'tooltip-target-' + therapy_cronology__.id">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                </svg>                                                  
                                            </v-btn>
                                            <b-tooltip :target="'tooltip-target-' + therapy_cronology__.id" triggers="hover">
                                                {{therapy_cronology__.description ? therapy_cronology__.description : "Nessuna spiegazione"}}
                                            </b-tooltip>      
                                        </div>                                                
                                        <div class="col-1">
                                            <button class="btn buttonDelete" @click="delete_therapy(therapy_cronology__.id)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                                </svg>                                          
                                            </button>
                                        </div>
                                    </div>


                                    <div class="form-group">

                                        <label for="id_therapy">Terapia attualmente seguita</label>

                                            <select id="id_therapy" class="form-control" v-model="form.patients_pathologies.id_therapy" @input="change_title_new_therapy($event.target.value)" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}">
                                                <option :value="null">
                                                    Nessuna terapia
                                                </option>
                                                <option v-for="therapy_by_pathology in therapies_by_pathology" v-bind:key="`therapy_by_pathology_${therapy_by_pathology.id}`" v-bind:value="therapy_by_pathology.therapy.id">
                                                    {{therapy_by_pathology.therapy.title}}
                                                </option>
                                            </select>

                                            <small class="form-text text-muted">
                                                Puoi modificare la terapia selezionandone una dalla lista
                                            </small>

                                    </div>
                                    <div class="form-group">

                                        <label for="description_edit">Motivazioni sulla modifica</label>

                                        <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}"></textarea>

                                        <small class="form-text text-muted">
                                            Fornisci una breve spiegazione sulla terapia impostata
                                        </small>
                                        <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                            {{validation.ko_update_therapy}}
                                        </div>
                                        <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                            {{validation.ok_update_therapy}}
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.form_edit_therapy.description.maxLength">
                                            La motivazioni devono essere inferiori a 700 caratteri
                                        </div>

                                    </div>

                                    <button class="btn btn-ifo btn-sm" type="button" :disabled="functionDisabled(10, 'update') || loaders.updating || $v.form.form_edit_therapy.$invalid" @click="update_therapy">
                                        Modifica terapia
                                    </button>

                                </div>

                                <div class="alert alert-danger" v-else>
                                    <u>ATTENZIONE</u>! Per questa patologia non sono state associate delle terapie
                                </div>
                            </div>
                        </div>

                        <!-- Se è stata selezionata la stanza e l'appuntamento non è urgente -->
                        <div v-show="form.appointment.id_room && !form.appointment.urgent">

                            <p class="font-weight-bold m-0">Agenda ed informazioni settimanali</p>

                            <p class="text-muted mb-3">
                                <small>
                                    Per creare un appuntamento clicca il giorno desiderato.
                                </small>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" @click="$refs.vuecal.previous(); updateYear();" class="btnCreateUpdateCal">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>                                   
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="button" @click="$refs.vuecal.next(); updateYear();" class="btnCreateUpdateCalN">                                 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg>                                         
                                    </button>
                                </div>
                            </div>


                            <template v-if="this.classCal == 2">
                                <div class="container-cal"></div>
                            </template> 

                            <loader v-if="loaders.reading.rooms" />

                            <vue-cal v-if="!loaders.reading.rooms"
                                locale                = "it"
                                ref                   = "vuecal"
                                :min-date             = "calendar.config.minDate"
                                :max-date             = "calendar.config.maxDate"
                                :events               = getEventsByRoomSelected(true)
                                :time-from            = "calendar.config.timeFrom"
                                :time-to              = "calendar.config.timeTo"
                                :time-step            = "calendar.config.timeStep"
                                :hide-weekdays        = "calendar.config.hideWeekDays"
                                :selected-date        = "calendar.config.selectedDate"
                                :drag-to-create-event = "false"
                                :transitions          = "false"
                                :disable-views        = "['years', 'days']"
                                :special-hours        = getRoomAvailibilities   
                                class                 = "mb-3 card-calendar createUpdateCal"                     
                                @cell-click           = "setAppointmentParams($event)"
                            />                              

                            <div class="alert alert-warning" v-show="validation.conflict">
                                {{validation.conflict}}
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group" v-if="!form.appointment.urgent">

                                        <label for="date_appointment">Data appuntamento</label>

                                        <input type="date" class="form-control" id="date_appointment" @blur="updateCheckRoomMaxVisits" :min="calendar.config.minDate" :max="calendar.config.maxDate" v-model="$v.form.appointment.date.$model" v-bind:class="{'is-invalid': (!$v.form.appointment.date.required || !$v.form.appointment.date.minValue) && $v.form.appointment.date.$dirty}" ref="data_appointment" @change="controlla_data">
                                        
                                        <small class="form-text text-muted">Clicca nel calendario il giorno richiesto</small>
                                        
                                        <div class="invalid-feedback" v-show="!$v.form.appointment.date.required && $v.form.appointment.date.$dirty">
                                            Data appuntamento richiesta
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.appointment.date.minValue && $v.form.appointment.date.$dirty">
                                            La data deve essere impostato da domani
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="appointment_h_start">Orario inizio appuntamento</label>

                                        <input type="time" v-model="$v.form.appointment.h_start.$model" @input="updateTimeEndAppointment" class="form-control" id="appointment_h_start" v-bind:class="{'is-invalid': !$v.form.appointment.h_start.required && $v.form.appointment.h_start.$dirty}">
                                        
                                        <small class="form-text text-muted">Imposta un orario in base alle disponibilità</small>

                                        <div class="invalid-feedback" v-show="!$v.form.appointment.h_start.required && $v.form.appointment.h_start.$dirty">
                                            Orario inizio richiesto
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">

                                        <label for="appointment_h_end">Orario termine appuntamento</label>

                                        <input type="time" v-model="$v.form.appointment.h_end.$model" @blur="updateCheckRoomMaxVisits" class="form-control" id="appointment_h_end" :disabled="this.functionSpecialEnabled(1) == false" v-bind:class="{'is-invalid': !$v.form.appointment.h_end.required}">

                                        <small class="form-text text-muted" v-show="!this.functionSpecialEnabled(1)">L'orario termine viene impostato automaticamente in base alla durata terapia</small>
                                        
                                        <div class="invalid-feedback" v-show="!$v.form.appointment.h_end.required">
                                            Orario termine richiesto
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div v-if="therapy_program">

                                <p class="font-weight-bold m-0">Programma teraupetico</p>

                                <p class="text-muted mb-3">
                                    <small class="text-success">
                                        E' stato associato 
                                    </small>
                                    <small>
                                        un programma teraupetico per questo paziente, se vuoi applicarlo spunta la casella
                                    </small>
                                </p>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">

                                            <label for="quantity">Quantità di visite</label>
                                            <input type="number" :value="therapy_program.quantity" class="form-control" id="quantity" disabled>

                                            <small class="form-text text-muted">
                                                Quantità di appuntamenti che verranno creati
                                            </small>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">

                                            <label for="frequency">Frequenza settimanale</label>
                                            <input type="number" :value="therapy_program.frequency" class="form-control" id="frequency" disabled>

                                            <small class="form-text text-muted">
                                                Ogni quante <b>settimane</b> si deve ripetere l'appuntamento
                                            </small>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">

                                    <div class="custom-control custom-checkbox text-truncate">
                                        <input type="checkbox" class="custom-control-input" id="create" v-model="req_activation_therapy_program">
                                        <label class="custom-control-label" for="create">Utilizza programma teraupetico</label>
                                    </div>

                                </div>

                            </div>

                            <!--<div class="row mt-2 mb-2" v-show="form.appointment.date">
                                <div class="col-lg-12">                  
                                    <p class="font-weight-bold m-0">Aggiungi nuova terapia</p>
                                </div>
                                <div class="col-lg-3">
                                    <label for="id_duration">Durata terapia:</label> 
                                    <select class="form-control mb-2" id="id_duration" @change="onChangeDuration($event.target.value)" v-model="DB.selected_duration">
                                        <option value="0">Seleziona</option>
                                        <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                            {{duration.title}} - {{duration.duration}} min.
                                        </option>
                                    </select> 
                                </div>  
                                <div class="col-lg-3" v-if="DB.selected_duration > 0">
                                    <p class="m-0 mt-1">
                                        <label for="date_appointment_day_after">Giorno terapia</label>
                                    </p> 

                                    <input type="date" class="form-control" id="date_appointment_day_after" @blur="readChairs" v-model="form.appointment.appointment_day_after_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_appointment_day_after" readonly disabled>
                                </div>    

                                <div class="col-lg-3" v-if="DB.selected_duration > 0">
                                    <p class="m-0">
                                        <label for="appointment_h_start">Orario terapia</label>
                                    </p>
                                    <input type="time" class="form-control" id="appointment_day_after_h_start" @blur="readChairs" v-model="form.appointment.appointment_day_after_time"  readonly disabled>
                                </div>                                                                  
        

                            </div>
                            <div class="row">


                                <div class="col-lg-12">
                                                                            
                                    <p v-if="form.appointment.id_user_sel_chair" v-show="DB.selected_duration > 0" class="font-weight-bold m-1">
                                        Modifica poltrona     
                                    </p>

                                    <p v-else class="font-weight-bold m-0 mt-1" v-show="DB.selected_duration > 0">Seleziona poltrona</p>      

                                    <div class="form-group">

                                        <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                        <select class="form-control" id="id_chair" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                            <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                            <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                {{chair.title}} {{chair.number}} - {{chair.description}}
                                            </option>                                                
                                        </select> 

                                        <div v-if="DB.selected_duration > 0 && DB.selected_duration_chair == 0" class="mt-1">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <label for="id_chair" v-show="DB.selected_duration > 0">oppure seleziona poltrona dal calendario</label> 
                                                </div>
                                                <div class="col-lg-6 text-right text-success" v-if="this.DB.detail_chair.length != 0">
                                                    <strong>Hai selezionato la {{ this.DB.detail_chair.title }} {{ this.DB.detail_chair.number }}</strong>
                                                </div>                                                                    
                                            </div>
                                            
                                            <vue-cal   
                                                locale                = "it"
                                                ref                   = "vuecal_chairs"
                                                :min-date             = "minDate"
                                                :max-date             = "maxDate"
                                                :events               = "events"
                                                :time-from            = "timeFrom"
                                                :time-to              = "timeTo"
                                                :time-step            = "timeStep"
                                                :hide-weekdays        = "calendar.config.hideWeekDays"
                                                :selected-date        = "calendar.config.selectedDate"
                                                :split-days           = "splitDays"
                                                :sticky-split-labels  = "stickySplitLabels"
                                                :drag-to-create-event = "false"
                                                :transitions          = "true"
                                                :min-cell-width        ="minCellWidth"
                                                :min-split-width      = "minSplitWidth"                                                                
                                                :disable-views        = "['years', 'year', 'month', 'months', 'week', 'weeks']"
                                                class                 = "mt-2 card-calendar-chairs createUpdateCal"                     
                                                @cell-click           = "setChairsDate($event)"
                                            />
                                        </div>                                                        
                                    </div>
                                </div>
                            </div> -->           

                            <div class="row">
                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il paziente tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row" v-if="req_activation_therapy_program">
                                        <div class="col-lg-8">
                                            <div class="alert alert-danger">
                                                Con il programma teraupetico attivo, il paziente verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phone" :disabled="!form.user.phone">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email" :disabled="!form.user.email">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il caregiver tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row" v-if="req_activation_therapy_program">
                                        <div class="col-lg-8">
                                            <div class="alert alert-danger">
                                                Con il programma teraupetico attivo, il caregiver verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phonecg" :disabled="!form.user.phonecg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg" :disabled="!form.user.emailcg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>                                
                            </div>       

                            <!--<div class="form-group mb-0">
                                <div class="custom-control custom-checkbox">

                                    <input type="checkbox" class="custom-control-input" id="notify_phone" v-model="notify.phone" :disabled="!form.user.phone">

                                    <label class="custom-control-label" for="notify_phone" v-if="form.user.phone">
                                        Notifica il paziente al numero <b><u>{{form.user.phone}}</u></b>
                                    </label>

                                    <label class="custom-control-label text-warning" for="notify_phone" v-else>
                                        <small>
                                            <i>
                                                <b>
                                                    Non è presente alcun numero di telefono
                                                </b>
                                            </i>
                                        </small>
                                    </label>

                                </div>
                            </div>
                            <div class="form-group">

                                <div class="custom-control custom-checkbox">

                                    <input type="checkbox" class="custom-control-input" id="notify_email" v-model="notify.email" :disabled="!form.user.email">

                                    <label class="custom-control-label" for="notify_email" v-if="form.user.email">
                                        Notifica il paziente al'indirizzo email <b><u>{{form.user.email}}</u></b>
                                    </label>

                                    <label class="custom-control-label text-warning" for="notify_email" v-else>
                                        <small>
                                            <i>
                                                <b>
                                                    Non è presente alcun indirizzo email
                                                </b>
                                            </i>
                                        </small>
                                    </label>

                                </div>
                            </div>-->

                            <div v-if="validation.ko != 'Terapia eliminata con successo!'">
                                <div v-show="validation.ko" class="alert alert-danger text-center">
                                    {{validation.ko}}
                                </div>
                            </div>
 
                            <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating || disabled == 1">
                                    
                                <span v-show="!loaders.creating">
                                    Aggiungi
                                </span>

                                <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                            </button>

                        </div>

                    </form>

                </div>
            </div>
            
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    //import DoughnutChart from './DoughnutChart.js'
    import VueCal        from 'vue-cal'
    import moment        from 'moment'
    import store         from '@/store'

    import 'vue-cal/dist/i18n/it.js'
    import 'vue-cal/dist/vuecal.css'

    var arr = new Array();  
    var objroom = new Array(); 
    var list2 = new Object(); 
    var list = new Array();     
    var availibilities = [];
    var newObj = {}; 
    var days_holiday_obj = {}
    var list2Holiday = new Object(); 
    var listHoliday = new Array();  
    var myString = []; 
    var objroomRoomAvailable = [];
    var arrRoomAvailable = []; 
    var objroomMaxVisit = [];
    var arrMaxVisit = [];     

    export default {
        components: {
            //DoughnutChart,
            VueCal
        },
        data() {
            return {  
                session_duration: 20,
                stickySplitLabels: false,
                minCellWidth: 100,
                minSplitWidth: 100,   
                minDate: moment().add(1, "days").format("YYYY-MM-DD"),
                maxDate:      null,
                timeFrom:     7*60,
                timeTo:       20*60,
                timeStep:     30,             
                splitDays: [],
                events: [],               
                selected_duration: 0,
                yearCal: new Date().getFullYear(), 
                classCal: 1, 
                availibilities2: [],    
                newObj2: {},  
                DB: {
                    durations: [],
                    chairs: [],
                    selected_duration: 0,
                    selected_duration_chair: 0,
                    detail_chair: []
                },                        
                patients: [

                ],
                pathologies: {
                    DB: []
                },
                therapies: {
                    DB: []
                },
                rooms: {
                    DB: []
                },
                room: {
                    DB: []
                }, 
                visit_types: {
                    DB: []
                }, 
                visit_type: {},                                             
                appointments: {
                    DB: []
                },
                appointments_patient: {
                    DB: []
                },                
                form: {
                    user: {
                        CF:         "",
                        name:       "",
                        surname:    "",
                        email:      null,
                        phone:      null,
                        emailcg:    null,
                        phonecg:    null,                        
                        note:       null
                    },
                    appointment: {
                        date:                 ``,
                        h_start:              ``,
                        h_end:                ``,
                        id_room:              null,
                        id_visit_type:        0,
                        id_patient_pathology: null,
                        urgent:               false,
                        deleteTherapy:        0,
                        id_chair:             0,
                        outside:              2,
                        appointments_day_after: {
                            id: "",
                            date: "",
                            h_start: "",
                            h_end: ""
                        }, 
                        duration_chair:       0,
                        appointment_day_after_date:     "",
                        appointment_day_after_time:     ""                                                        
                    },
                    period:       null,
                    room:         null,
                    visit_type:   0,
                    patients_pathologies: {
                        id_therapy: null,
                        id:         null
                    },
                    form_edit_therapy: {
                        id:                   0,
                        description:          null,
                        id_patient_pathology: null,
                        id_therapy:           null,
                        title_old_therapy:    null,
                        therapy_on_select:    null,
                        modifier_user:        this.$store.state.auth.username
                    }
                },
                search: {
                    names:    [],
                    surnames: [],
                    CFs:      []
                },
                validation: {
                    ko: false,
                    ko_update_therapy: false,
                    ok_update_therapy: false,
                    ko_search_patient: false,
                    ok_search_patient: false,
                    conflict:          false
                },
                loaders: {
                    creating: false,
                    reading: {
                        therapy:  false,
                        patients: false,
                        rooms: false
                    },
                    update_therapy: false,
                    delete_therapy: false
                },

                chart: {
                    options: {
                        legend: {
                            display: false
                        }
                    },
                    dataCollection: {
                        labels: [
                            'Appuntamenti effettuati',
                            'Appuntamenti da effettuare'
                        ],
                        datasets: [
                            {
                                data: [0, 0],
                                backgroundColor: [
                                    '#5c1b24',
                                    '#343a40'
                                ]
                            }
                        ]
                    },
                    myLegend: {
                        effettuate:   0,
                        daEffettuare: 0
                    },
                    update: 0
                    
                },
                patients_pathologies:[],
                therapy: null,
                calendar: {
                    config: {
                        minDate:      moment().add(1, "days").format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     20,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [6,7],
                        selectedDate: null,
                        specialHours: []  
                    }
                },
                therapy_program: null,
                req_activation_therapy_program: false,
                notify: {
                    phone: false,       
                    email: false,
                    phonecg: false,
                    emailcg: false
                }                
            }
        },
        validations() {
            return {                     
                disabled: 0, //bottone disabilitato oppure no
                form: {
                    user: {
                        CF: {
                            minLength: minLength(3),
                        },
                        name: {
                            minLength: minLength(2),
                        },
                        surname: {
                            minLength: minLength(2),
                        }
                    },
                    appointment: {
                        date: {
                            required,
                            minValue: value => value > moment().format("YYYY-MM-DD")
                        },
                        h_start: {
                            required
                        },
                        h_end: {
                            required
                        }
                    },
                    form_edit_therapy: {
                        description: {
                            maxLength: maxLength(700)
                        }
                    }
                }
            }
        },
        methods: {
            setChairsDate(event) {
                console.log(event)

                const date = event.date
                const chair = event.split

                this.form.appointment.id_chair = chair

                this.read_detail_chair(chair)


                this.form.appointment.appointment_day_after_date    = moment(date).format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(date).format("HH:mm")
                this.loaders.updating = false;
            },  
            async read_detail_chair(id) {
                try {

                    this.DB.detail_chair = (
                        await this.axios.get(`user/chairs/read/detail/${id}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    console.log(this.DB.detail_chair);

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },                               
            readChairs() {
                this.read_chairs_with_appointments()
                this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date
            },  
            async read_chairs_with_appointments() {

                const id = 0

                try {
                    const chairs_ = (
                        await this.axios.post(`user/chairs/read/all_with_appointments`, {id}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.splitDays = []
                    this.events = []
                    var splitDays_obj = {} 
                    var events_obj = {}       
                    for(const chair of chairs_) {

                        splitDays_obj = {
                            id: chair.id, 
                            class: "chair_split", 
                            label: chair.title + " "  + chair.number
                        }

                        if(chair.appointments){

                        
                            for(const appointment of chair.appointments) {
                                if(appointment.appointments_day_after){
                                    events_obj = {
                                        start: moment(appointment.appointments_day_after.h_start).format('YYYY-MM-DD HH:mm'),
                                        end: moment(appointment.appointments_day_after.h_end).format('YYYY-MM-DD HH:mm'),
                                        title: chair.title + ' '  + chair.number + ' occupata dalle ore ' + moment(appointment.appointments_day_after.h_start).format('HH:mm') + ' alle ore: '  + moment(appointment.appointments_day_after.h_end).format('HH:mm'),
                                        content: '',
                                        class: 'chairs_event',
                                        split: chair.id                            
                                    } 
                                    this.events.push(events_obj)     
                                }                                                
                            } 
                        }


                        this.splitDays.push(splitDays_obj)

                        
                    }  
                    
                    
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },                         
            async onChangeDuration(event) {
                const OBJ = {
                    id: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    h_start: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_start : this.form.appointment.date + 'T' + this.form.appointment.h_start + ':00.000Z',
                    h_end: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_end : this.form.appointment.date + this.form.appointment.h_end + ':00.000Z',
                    date: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.date : this.form.appointment.date + '00:00:00.000Z'             
                }
                this.read_chairs_with_appointments()
                this.DB.selected_duration = event
                this.form.appointment.duration_chair = event

                try {
                    const chairs_unavailables = (
                        await this.axios.post(`user/chairs/read/all_by_duration/exclude/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.DB.chairs = (
                        await this.axios.post(`user/chairs/read/all_by_duration/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data 
                    
                    chairs_unavailables.forEach((value) => {   
                        var chair_unavailables_ = value.id_chair
                    
                        this.DB.chairs = this.DB.chairs.filter(d => d.id != chair_unavailables_);
                    })                      
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }                     
       
    
            },  
  
            onChangeChair(event) {
                this.DB.selected_duration_chair = event.target.value
                this.form.appointment.id_chair = event.target.value
            },           
            async read_durations() {
                try {

                    this.DB.durations = (
                        await this.axios.get(`user/chairs_duration/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },   
            async selectChair() {
                
                const OBJ = {
                    id_appointment:     this.$route.params.id,
                    id_chair:           this.form.appointment.id_chair                 
                }               
          

                try {

                    const pause = await this.axios.post(`user/appointments/sentChair`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },             
            controlla_data() {

                // Controlliamo se la data inserita è minore o uguale ad oggi


                const data_inserita = this.$refs.data_appointment.value

                var date = new Date(data_inserita);

                const day = date.getDay();            

                if(data_inserita == '' || !data_inserita || moment(data_inserita).isSameOrBefore(moment().format("YYYY-MM-DD"))) {
                    
                    this.$toast.open({
                        message: "La data inserita deve partire da domani",
                        type: "error"
                    })
                    this.loaders.creating = true  

                    this.$refs.data_appointment.value = this.form.appointment.date
                } else if(day == 6 || day == 0){
                    this.$toast.open({
                        message: "Non è consentito inserire un appuntamento di sabato o di domenica!",
                        type: "error"
                    })   
                    this.loaders.creating = true                
                } else {
                    this.form.appointment.date = this.$refs.data_appointment.value
                    this.loaders.creating = false 
                }

            },            

            updateYear(){
                var datefull = document.querySelector(".vuecal__title button");
                datefull = datefull.innerHTML;
                var lastChars = datefull.substr(datefull.length - 5);
                var year = lastChars.replace(')', '');
                this.yearCal = year;
            },
            async readVisitTypes() {

                try {

                    const visit_types = await this.axios.get(`user/visit_types/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.visit_types.DB = visit_types.data  

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.visit_types.DB = []

                }

            },

            async readVisitType(visittypeid) {
                this.visit_type = this.session_duration
                try {

                    const visit_type = await this.axios.get(`user/visit_types/read/detailId/${visittypeid}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.visit_type = visit_type.data[0].duration  
                    
                    this.updateTimeEndAppointment() 
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.visit_type = 0

                }

            }, 

            async readRooms() {

                try {

                    const rooms = await this.axios.get(`user/rooms/read/actives/appointments/update`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.rooms.DB = rooms.data     

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.rooms.DB = []

                }

            },

            async readRoom(roomid) {

                this.loaders.reading.rooms = true
            
                try {

                    const room = await this.axios.get(`user/rooms/read/detailId/${roomid}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.room.DB = room.data.availibilities  

                    this.loaders.reading.rooms = false
                   
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.loaders.reading.rooms = false

                    this.room.DB = []

                }

            }, 
                    
            async createAppointment() {

                this.loaders.creating = true
                this.validation.ko    = false

                // Se è true il programma teraupetico, sostituisci con id
                if(this.req_activation_therapy_program) {
                    this.req_activation_therapy_program = this.therapy_program.id
                }

                // Costruzione oggetto da inviare all'API
                const OBJ = {
                    form: this.form,
                    req_activation_therapy_program: this.req_activation_therapy_program,
                    notify: {
                        email: this.notify.email,
                        phone: this.notify.phone,
                        emailcg: this.notify.emailcg,
                        phonecg: this.notify.phonecg                     
                    }
                }

                try {
                    const res = await this.axios.post(`user/appointments/create`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.go(-1)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async readByFilter(keyOfSreach, val, where, orderBy) {

                // Solo pazienti visibili
                where.state = true

                if(val.length >= 3) {
                    try {

                        const res = await this.axios.post(`user/patients/read/search`, {where, orderBy}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.search[keyOfSreach] = res.data

                    }
                    catch(e) {

                        this.search[keyOfSreach] = []

                        this.$toast.open({
                            message: e.response.data.msg,
                            type:   `error`
                        })

                    }
                }
                else {
                    this.search[keyOfSreach] = []
                }

            },
            async read_by_filter_new() {
                const {
                    name,
                    surname,
                    CF
                } = this.form.user

                this.validation.ko_search_patient = false
                this.validation.ok_search_patient = false

                if(name.length >= 3 || surname.length >= 3 || CF.length >= 3) {

                    this.loaders.reading.patients = true

                    try {

                        this.patients = (

                            await this.axios.post(

                                "user/patients/read/search_custom",

                                {
                                    name,
                                    surname,
                                    CF
                                },
                                {
                                    headers:{
                                        Authorization: `bearer ${this.$store.state.auth.token}`
                                    }
                                }

                            )

                        ).data

                        this.validation.ok_search_patient = `Trovati ${this.patients.length} pazienti`

                    }
                    catch(e) {

                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type:   `error`
                        })

                        this.validation.ko_search_patient = message
                        this.patients                     = []

                    }
                    finally {
                        this.loaders.reading.patients = false
                    }

                }
                else {

                    // Svuota array
                    if(this.patients.length > 0) this.patients = []

                }

            },
            async readAppointments() {

                // Ricerca appuntamenti
                let where = {}

                if(this.functionSpecialEnabled(1)) {
                    where = {
                        id_room: this.form.appointment.id_room
                    }
                }

                try {                  
                    const appointments = await this.axios.post(`user/appointments/read/search`, {where}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    // Reset del calendario
                    this.calendar.config.events = []

                    for(let appointment of appointments.data) {
                        this.calendar.config.events.push({
                            start: `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_start).format('HH:mm')}`,
                            end:   `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_end).format('HH:mm')}`,
                            title:  appointment.patient_pathology.patient.name,
                            class: 'color-event'
                        })
                    }

                    this.appointments.DB = appointments.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },
            async read_patient_pathology() {

                this.loaders.reading.therapy = true

                try {

                    //console.log("this.form.appointment.id_patient_pathology", this.form.appointment.id_patient_pathology)

                    const {patient_pathology} = (await this.axios.get(`user/patients_pathologies/read_therapy/${this.form.appointment.id_patient_pathology}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })).data

                    if((patient_pathology.therapy && this.form.patients_pathologies.id_therapy == patient_pathology.therapy.id) || (!this.form.patients_pathologies.id_therapy && !patient_pathology.therapy)) {

                        this.$toast.open({
                            message: "Non sono presenti dei cambiamenti per la terapia",
                            type:    "warning"
                        })

                    }
                    else {

                        this.form.patients_pathologies.id_therapy = patient_pathology.therapy ? patient_pathology.therapy.id : null

                        this.$toast.open({
                            message: "Aggiornamento terapia caricato con successo",
                            type:    "success"
                        })
                    }

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading.therapy = false
                }
            },
            async update_therapy() {

                this.loaders.update_therapy       = true
                this.validation.ko_update_therapy = false
                this.validation.ok_update_therapy = false

                try {

                    // Popoliamo l'oggetto
                this.form.form_edit_therapy.id_patient_pathology = this.form.appointment.id_patient_pathology
                this.form.form_edit_therapy.id_therapy           = this.form.patients_pathologies.id_therapy
                this.form.form_edit_therapy.modifier_user        = this.$store.state.auth.username


                    const therapy_updated = await this.axios.post(`user/patients_pathologies/update_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.form_edit_therapy.id = therapy_updated.data.therapy_cronology.id

                    this.form.form_edit_therapy.date = new Date()

                    const OBJ = {...this.form.form_edit_therapy}
                    // Inseriamo ID utile per la computed che recupera la cronologia
                    OBJ.id = therapy_updated.data.therapy_cronology.id
                    
                    // Mostriamo nella tabella la modifica
                    for(const patient_pathology of this.patients_pathologies) {
                        if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                            patient_pathology.therapy_cronologies.push(OBJ)
                        }
                    }                    

                    // Impostiamo la nuova terapia scelta
                    this.form.form_edit_therapy.title_old_therapy = this.form.form_edit_therapy.therapy_on_select
                    this.form.form_edit_therapy.modifier_user = this.$store.state.auth.username

                    const {
                        message
                    } = therapy_updated.data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validation.ok_update_therapy = message

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_update_therapy = message

                }
                finally {
                    this.loaders.update_therapy = false
                }

            },

            async delete_therapy(id_cronology) {

                this.loaders.delete_therapy       = true
                this.validation.ko_delete_therapy = false
                this.validation.ok_delete_therapy = false

                try {
                    this.form.appointment.deleteTherapy = 1
                    this.form.form_edit_therapy.id_therapy = id_cronology

                    const therapy_deleted = await this.axios.post(`user/patients_pathologies/delete_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    for(const patient_pathology of this.patients_pathologies) {
                        if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                            let updateCronologies = patient_pathology.therapy_cronologies.filter((el) => el.id !== id_cronology);
                            patient_pathology.therapy_cronologies = updateCronologies;                                
                        }
                    }                      
                    

                    
                    this.deleteRow = 1  

                    const {
                        message
                    } = therapy_deleted.data


                    this.validation.ok_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                finally {
                    this.loaders.delete_therapy = false

                }

            },
            async readTherapies() {

                try {

                    this.therapies.DB = (
                        await this.axios.get(`user/therapies/read/actives`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: "Errore di connessione con il server",
                        type: "error"
                    })

                    this.$router.go("/user/agenda")
                }

            },

            updateFormUser(keyOfSreach, values) {

                for(let key in values) {
                    this.form.user[key] = values[key]
                }

                this.search[keyOfSreach]  = []
                this.patients_pathologies = values.patients_pathologies

            },
            async ricerca_asur() {
  
                // Uppercase
                const CF = this.form.user.CF.toUpperCase()

                const {
                    patient
                } = (
                    await this.axios.post(`user/patients/search/asur`, {CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                ).data

                /*if(patient.comune == "ROMA"){
                    this.form.appointment.outside = 0
                } else {
                    this.form.appointment.outside = 1
                }*/

                if(patient.decesso != ""){
                    const message = "Paziente deceduto"

                    this.$toast.open({
                        message: message,
                        type: `error`
                    })

                    this.patients_pathologies = []
                    this.validation.ko    = message
                    this.loaders.creating = false
                    return false
                }

            },           
            update_form_user_new(patient) {

                // Prendiamo i parametri passati per valore
                const {
                    patients_pathologies,
                    name,
                    surname,
                    CF,
                    phone,
                    email,
                    phonecg,
                    emailcg
                } = patient

                // Popoliamo i campi input di ricerca
                this.form.user.name    = name
                this.form.user.surname = surname
                this.form.user.CF      = CF
                this.form.user.phone   = phone
                this.form.user.email   = email
                this.form.user.phonecg = phonecg
                this.form.user.emailcg = emailcg             

                this.ricerca_asur()

                if(patients_pathologies.length > 0) {

                    // Mostriamo le associazioni tra paziente e patologie
                    this.patients_pathologies = patients_pathologies
                    this.session_duration = patients_pathologies.sessions_duration
                    // Svuotiamo lista dei pazienti
                    this.patients = []

                }
                else {

                    // Svuotiamo lista tra paziente e patologie
                    this.patients_pathologies = []

                    this.$toast.open({
                        message: "Nessuna patologia associata",
                        type: "warning"
                    })
                }

            },
            updateCalendar() {

                // Ricerca degli appuntamenti
                this.readAppointments()
                // Set giorno da visualizzare ad oggi
                this.calendar.config.selectedDate = moment().format("YYYY-MM-DD")

            },
            updateDayOfAppointment(dateSelected) {
               
                this.form.appointment.date = moment(dateSelected).format(`YYYY-MM-DD`)
                this.form.appointment.appointment_day_after_date = moment(dateSelected).format(`YYYY-MM-DD`)

            },
            updateTimeEndAppointment() {
                if(this.classCal == 1){
                    
                    if(this.visit_type > 0){
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                                         
                    } else {
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")               
                    } 
                    if(this.check_if_present_appointment()) {

                        const message = "L'appuntamento che stai creando va in conflitto con un altro appuntamento!"

                        this.validation.conflict = message
                        this.disabled = 1
                        } else {
                        this.validation.conflict = false
                        this.disabled = 0                                
                    }                      
                                           
                } else {

                    if(this.visit_type > 0){
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")                      
                    } else {
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")               
                    }    
                                   
                    if(this.check_if_present_appointment()) {

                        const message = "L'appuntamento che stai creando va in conflitto con un altro appuntamento!"

                        this.validation.conflict = message
                        this.disabled = 1
                        } else if(this.check_room_hours_available()){
                        const message = "La stanza non è disponibile in questa fascia oraria!"

                        this.$toast.open({
                            message,
                            type: "warning"
                        })

                        this.validation.conflict = message
                        this.disabled = 1     
                        } else {
                        this.validation.conflict = false
                        this.disabled = 0                       
                    }         
   
                }    
            },
            updateCheckRoomMaxVisits() {

                this.check_if_present_appointment_patient();
                // Controlliamo esistenza appuntamenti gia presenti nella fascia oraria
                /*if(this.check_max_visits_for_room()) {
                    if(this.functionSpecialEnabled(6)){
                            this.$confirm("E' stato raggiunto il numero massimo di visite per questa stanza! Sei sicuro di voler aggiungere l'appuntamento?", "", "warning").then(() => {
                            this.validation.conflict = false
                            this.disabled = 0
                        });
                    }
                    const message = "La stanza ha raggiunto il numero massimo di visite giornaliere!"

                    /*this.$toast.open({
                        message,
                        type: "warning"
                    })*/
                    /*this.validation.conflict = message

                    //bottone disabilitato
                    this.disabled = 1
                    this.form.appointment.date = "gg/mm/aaaa"

                } else*/      
                if(this.check_room_max_visits_minutes()) {
                    if(this.functionSpecialEnabled(6)){
                            this.$confirm("E' stato raggiunto il numero massimo di visite per questa stanza! Sei sicuro di voler aggiungere l'appuntamento?", "", "warning").then(() => {
                            this.validation.conflict = false
                            this.disabled = 0
                        });
                    }

                    
                    const message = "E' stato raggiunto il numero massimo di visite per questa stanza!"

                    this.$toast.open({
                        message,
                        type: "warning"
                    })

                    this.validation.conflict = message
                    //bottone aggiungi disabilitato
                    //this.disabled = 1                       

                } else {
                    this.validation.conflict = false

                    //bottone abilitato
                    this.disabled = 0
                }

            },
            
            functionSpecialEnabled(id_function) {

                // Se sono presenti abilitazioni all'utilizzo delle funzioni software
                if(store.getters.softwareSpecialFunctions) {
                    //sFunction == software function
                    for(let sFunction of store.getters.softwareSpecialFunctions) {
                        // Se la funzione software corrisponde all'ID passato
                        if(sFunction.id_software_special_function == id_function && sFunction.abilitation) {
                            return true
                        }
                    }
                }

                return false

            },
            setAppointmentParams(data_selezionata) {

                this.form.appointment.date    = moment(data_selezionata).format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_date     = moment(data_selezionata).add(1, "day").format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(data_selezionata).format("HH:mm") 
                this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date
                
                if(this.check_last_appointment() != null){
                    var fulldate = this.check_last_appointment();                         

                    this.form.appointment.h_start = moment(fulldate).format(`HH:mm`)
                    this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                } else {
                    this.form.appointment.h_start = moment(data_selezionata).format("HH:mm")    
                }     
                      
                this.loaders.creating = false;
                
                this.updateTimeEndAppointment()

                // Controlliamo esistenza appuntamenti gia presenti nella fascia oraria
                /*if(this.check_if_present_appointment()) {
                    this.$toast.open({
                        message: "L'appuntamento che stai creando va in conflitto con un altro appuntamento!",
                        type: "warning"
                    })
                }*/

                // Trigger di vuelidate per controllo sui campi input
                this.$v.$touch()
            },
            getAppointmentDuration() {
                return this.patients_pathologies.find(
                    patient_pathology_arr => patient_pathology_arr.id == this.form.appointment.id_patient_pathology
                ).sessions_duration
            },
            update_therapies(id_patient_pathology_selected) {

                // Impostiamo se è stata settata l'id della terapia associata tra paziente -> patologia in pathients_pathologies
                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == id_patient_pathology_selected) {
                        this.form.patients_pathologies.id_therapy = patient_pathology.id_therapy
                        this.therapy_program                      = patient_pathology.therapy_program
                        this.form.patients_pathologies.id         = patient_pathology.id
                        this.form.form_edit_therapy.title_old_therapy = patient_pathology.therapy ? patient_pathology.therapy.title : null
                        this.form.form_edit_therapy.therapy_on_select = patient_pathology.therapy ? patient_pathology.therapy.title : null
                    }
                }

            },
            change_title_new_therapy(id_therapy) {

                if(!id_therapy) {
                    this.form.form_edit_therapy.therapy_on_select = null
                }
                else {

                    // Ciclo delle terapie per ricavare il titolo
                    for(const therapy of this.therapies.DB) {
                        if(therapy.id == id_therapy) {
                            this.form.form_edit_therapy.therapy_on_select = therapy.title
                        }
                    }

                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            set_params_for_appointment_urgent() {

                // Controlliamo stato flag
                if(this.form.appointment.urgent) {

                    this.change_date_now_in_calendar("SET")
                    this.turn_off_therapy_program()
                    this.turn_off_notifications()

                    this.$toast.open({
                        message: "Hai impostato l'appuntamento come urgente!",
                        type: "success"
                    })

                }
                else {

                    this.change_date_now_in_calendar("POP")

                    this.$toast.open({
                        message: "Hai impostato l'appuntamento come normale",
                        type: "success"
                    })

                }

            },
            change_date_now_in_calendar(action) {

                if(action == "SET") {

                    // Impostiamo data e orario di adesso
                    this.form.appointment.date    = moment().format("YYYY-MM-DD")
                    this.form.appointment.h_start = moment().format("HH:mm")

                    // Impostazione automatica del termine appuntamento
                    this.updateTimeEndAppointment()

                }
                else if(action == "POP") {

                    // Rimuoviamo data e orari di adesso
                    this.form.appointment.date    = ""
                    this.form.appointment.h_start = ""
                    this.form.appointment.h_end   = ""

                }
                else {
                    console.alert("action not specified")
                }

            },
            turn_off_therapy_program() {

                this.req_activation_therapy_program = false

                this.$toast.open({
                    message: "Programma teraupetico non attivo",
                    type: "warning"
                })

            },
            turn_off_notifications() {

                this.notify.phone = false
                this.notify.email = false
                this.notify.phonecg = false
                this.notify.emailcg = false               

                this.$toast.open({
                    message: "Notifiche non attive",
                    type: "warning"
                })

            },
            check_room_hours_available() {

                this.rooms.DB.find(
                    room => {
                        
                        if(room.id == this.form.appointment.id_room) {

                            for(const room of room.availibilities) {  
                                objroomRoomAvailable.push(room);
                            }
                        }

                    }
                )  
                
                var h_start = this.form.appointment.h_start; 
                var date = this.form.appointment.date;

                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  
                           
                var data;
                var from;
                var to;
                var frompm;
                var topm;   

                for (let index = 0; index < objroomRoomAvailable.length; ++index) {
                    startDate = new Date(objroomRoomAvailable[index].dal);
                    endDate = new Date(objroomRoomAvailable[index].al);
                    
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dayweek = dt.getDay() - 1;
                         if(dayweek == '1'){
                            from = objroomRoomAvailable[index].monday_1.am.dal;
                            to = objroomRoomAvailable[index].monday_1.am.al;
                            frompm = objroomRoomAvailable[index].monday_1.pm.dal;
                            topm = objroomRoomAvailable[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroomRoomAvailable[index].tuesday_2.am.dal;
                            to = objroomRoomAvailable[index].tuesday_2.am.al;       
                            frompm = objroomRoomAvailable[index].tuesday_2.pm.dal;
                            topm = objroomRoomAvailable[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroomRoomAvailable[index].wednesday_3.am.dal;
                            to = objroomRoomAvailable[index].wednesday_3.am.al;     
                            frompm = objroomRoomAvailable[index].wednesday_3.pm.dal;
                            topm = objroomRoomAvailable[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroomRoomAvailable[index].thursday_4.am.dal;
                            to = objroomRoomAvailable[index].thursday_4.am.al; 
                            frompm = objroomRoomAvailable[index].thursday_4.pm.dal;
                            topm = objroomRoomAvailable[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroomRoomAvailable[index].friday_5.am.dal;
                            to = objroomRoomAvailable[index].friday_5.am.al;         
                            frompm = objroomRoomAvailable[index].friday_5.pm.dal;
                            topm = objroomRoomAvailable[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            from = objroomRoomAvailable[index].saturday_6.am.dal;
                            to = objroomRoomAvailable[index].saturday_6.am.al;   
                            frompm = objroomRoomAvailable[index].saturday_6.pm.dal;
                            topm = objroomRoomAvailable[index].saturday_6.pm.al;                                                        
                        } 
                                          
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arrRoomAvailable.push(data);
  
  
                    } 
                    
                    let dayF = ('0' + startDate.getDate()).slice(-2);       
                    let monthF = ('0' + (startDate.getMonth()+1)).slice(-2);        
                    let yearF = startDate.getFullYear();  

                    let dayFe = ('0' + endDate.getDate()).slice(-2);       
                    let monthFe = ('0' + (endDate.getMonth()+1)).slice(-2);        
                    let yearFe = endDate.getFullYear();      
                    let startDateF = yearF + monthF + dayF;            
                    let endDateF = yearFe + monthFe + dayFe;   
                    
                    let dateF = date.replace('-', '');
                    dateF = dateF.replace('-', '');
                    
                    for (let index = 0; index < arrRoomAvailable.length; ++index) {

                        var element = Object.keys(arrRoomAvailable[index]);
                        const dateNew = new Date(element);
                        var day = dateNew.getDay();                   

                        var fromMor = String(arrRoomAvailable[index][element].am.from);                   
                        var toMor = String(arrRoomAvailable[index][element].am.to);    

                        var fromMorS = parseInt(fromMor.replace(':', ''));              
                        var toMorS = parseInt(toMor.replace(':', ''));              

                        var fromAft = String(arrRoomAvailable[index][element].pm.from);                   
                        var toAft = String(arrRoomAvailable[index][element].pm.to);                

                        var fromAftS = fromAft.replace(':', '');              
                        var toAftS = toAft.replace(':', '');                               

                        var check_h_start = parseInt(h_start.replace(':', ''));                       

                        element = String(element);

                        if(date == element){

                            if(dateF >= startDateF && dateF <= endDateF){

                                if(fromMorS != '' && fromAftS == ''){
                                    if(!(check_h_start >= fromMorS && check_h_start <= toMorS) || day == 0){
                                        this.disabled = 1
                                        return true
                                    }                                      
                                } else if(fromMorS != '' && fromAftS != ''){
                                    if((!(check_h_start >= fromMorS && check_h_start <= toMorS) && !(check_h_start >= fromAftS && check_h_start <= toAftS)) || day == 0){
                                        this.disabled = 1
                                        return true
                                    } 
                                }  else if(fromMorS == '' && fromAftS != ''){
                                    if(!(check_h_start >= fromAftS && check_h_start <= toAftS) || day == 0){
                                        this.disabled = 1
                                        return true
                                    }                                    
                                }        

                            }                            
                            
                        } 

                    }                         

                }
      
            },
            check_room_max_visits_minutes() {

                this.rooms.DB.find(
                    room => {
                        
                        if(room.id == this.form.appointment.id_room) {

                            for(const room of room.availibilities) {  
                                objroomMaxVisit.push(room);
                            }
                        }

                    }
                )  

                var date = this.form.appointment.date;
                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  
                           
                var data;
                var from;
                var to;
                var frompm;
                var topm;   


                for (let index = 0; index < objroomMaxVisit.length; ++index) {
                    startDate = new Date(objroomMaxVisit[index].dal);
                    endDate = new Date(objroomMaxVisit[index].al);
                    
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dayweek = dt.getDay() - 1;
                         if(dayweek == '1'){
                            from = objroomMaxVisit[index].monday_1.am.dal;
                            to = objroomMaxVisit[index].monday_1.am.al;
                            frompm = objroomMaxVisit[index].monday_1.pm.dal;
                            topm = objroomMaxVisit[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroomMaxVisit[index].tuesday_2.am.dal;
                            to = objroomMaxVisit[index].tuesday_2.am.al;       
                            frompm = objroomMaxVisit[index].tuesday_2.pm.dal;
                            topm = objroomMaxVisit[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroomMaxVisit[index].wednesday_3.am.dal;
                            to = objroomMaxVisit[index].wednesday_3.am.al;     
                            frompm = objroomMaxVisit[index].wednesday_3.pm.dal;
                            topm = objroomMaxVisit[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroomMaxVisit[index].thursday_4.am.dal;
                            to = objroomMaxVisit[index].thursday_4.am.al; 
                            frompm = objroomMaxVisit[index].thursday_4.pm.dal;
                            topm = objroomMaxVisit[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroomMaxVisit[index].friday_5.am.dal;
                            to = objroomMaxVisit[index].friday_5.am.al;         
                            frompm = objroomMaxVisit[index].friday_5.pm.dal;
                            topm = objroomMaxVisit[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            from = objroomMaxVisit[index].saturday_6.am.dal;
                            to = objroomMaxVisit[index].saturday_6.am.al;   
                            frompm = objroomMaxVisit[index].saturday_6.pm.dal;
                            topm = objroomMaxVisit[index].saturday_6.pm.al;                                                        
                        } 
                                          
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arrMaxVisit.push(data);
  
  
                    }  
                                         
                }

                for (let index = 0; index < arrMaxVisit.length; ++index) {

                    var element = Object.keys(arrMaxVisit[index]);    
                
                    var fromMor = String(arrMaxVisit[index][element].am.from);                   
                    var toMor = String(arrMaxVisit[index][element].am.to);    


                    //var fromAft = String(arrMaxVisit[index][element].pm.from);                   
                    //var toAft = String(arrMaxVisit[index][element].pm.to);                
                     
                    
                    element = String(element);
                    var elementNew = new Date(date);

                    if(date == element){
                        //max visits

                        let dayMaxVisit = ('0' + elementNew.getDate()).slice(-2);       
                        let monthMaxVisit = ('0' + (elementNew.getMonth()+1)).slice(-2);        
                        let yearMaxVisit = elementNew.getFullYear();  
                        let hourMaxVisit = fromMor.split(':');
                        let hourMaxVisit2 = toMor.split(':');
                        
                        var startTimeMaxVisit = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit , hourMaxVisit[0], hourMaxVisit[1]);
                        var endTimeMaxVisit = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit, hourMaxVisit2[0], hourMaxVisit2[1]);

                        var totalMilisecondsMaxVisit = endTimeMaxVisit - startTimeMaxVisit;
                        var totalSecondsMaxVisit = totalMilisecondsMaxVisit/1000;
                        var totalMinutsMaxVisit = totalSecondsMaxVisit/60; 

                        var minutes;
                        for(const appointment of this.getEventsByRoomSelected(false)) {   
                            // Parametri appuntamento
                            const date_a    = moment(appointment.date).format("YYYY-MM-DD")
                            const h_start_a = moment(appointment.h_start).format("HH:mm")
                            const h_end_a   = moment(appointment.h_end).format("HH:mm")

                            var elementNewAppointment = new Date(date_a);

                            let dayAppointment = ('0' + elementNewAppointment.getDate()).slice(-2);       
                            let monthAppointment = ('0' + (elementNewAppointment.getMonth()+1)).slice(-2);        
                            let yearAppointment = elementNewAppointment.getFullYear();  
                            let hourAppointment = h_start_a.split(':');
                            let hourAppointment2 = h_end_a.split(':');
                            
                            var startTimeAppointment = new Date(yearAppointment, monthAppointment , dayAppointment , hourAppointment[0], hourAppointment[1]);
                            var endTimeAppointment = new Date(yearAppointment, monthAppointment , dayAppointment, hourAppointment2[0], hourAppointment2[1]);

                            var totalMilisecondsAppointment = endTimeAppointment - startTimeAppointment;
                            var totalSecondsAppointment = totalMilisecondsAppointment/1000;
                            var totalMinutsAppointment = totalSecondsAppointment/60; 
                            
                            minutes = totalMinutsMaxVisit - totalMinutsAppointment;           
                        }

                        var hourSelected =  this.form.appointment.h_start.split(':');  
                        var hourSelected2 =  this.form.appointment.h_end.split(':');  

                        var startTimeSelected = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit , hourSelected[0], hourSelected[1]);
                        var endTimeSelected = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit, hourSelected2[0], hourSelected2[1]);

                        var totalMilisecondsSelected = endTimeSelected - startTimeSelected;
                        var totalSecondsSelected = totalMilisecondsSelected/1000;
                        var totalMinutsSelected = totalSecondsSelected/60; 
                            
                        minutes = minutes - totalMinutsSelected;                        
                        if(minutes <= 0){
                            this.disabled = 1
                            return true;
                        } 
                    } 
                }                               
      
            },
            check_if_present_appointment() {

                // Parametri selezionati
                var {
                    h_start,
                    h_end,
                    date
                } = this.form.appointment

                for(const appointment of this.getEventsByRoomSelected(false)) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")
                    var h_start_a = moment(appointment.h_start).format("HH:mm")
                    var h_end_a   = moment(appointment.h_end).format("HH:mm")                

                    date = date.replaceAll('-', '');
                    date_a = date_a.replaceAll('-', '');
                    h_start = h_start.replaceAll(':', '');
                    h_start_a = h_start_a.replaceAll(':', '');
                    h_end = h_end.replaceAll(':', '');
                    h_end_a = h_end_a.replaceAll(':', '');

                    const conflitto = (
                        date_a == date
                    ) &&
                    (
                        h_end_a > h_start && h_start_a < h_end
                    )

                    /*const conflitto = (
                        date_a == date
                    ) &&
                    (
                        (
                            (
                                h_start > h_start_a
                            ) && 
                            (
                                h_start < h_end_a
                            )
                        ) ||
                        (
                            (
                                h_start < h_start_a
                            ) && 
                            (
                                h_end_a < h_end ||
                                h_end_a > h_end
                            )
                        )
                    )  */                 

                    if(conflitto) return true
                    
                }
            },
            async check_if_present_appointment_patient(){
                try {
                    const CF = this.form.user.CF.toUpperCase()

                    let patient = await this.axios.get(`user/patients/read/detail/${CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    if(patient.data.patients_pathologies) { 

                        // Popoliamo array degli appuntamenti estrapolandoli dalle terapie seguite
                        for(let patient_pathology of patient.data.patients_pathologies) {  
                            for(let appointment of patient_pathology.appointment) {
                                this.appointments_patient.DB.push(appointment)
                            }                          
                        }

                    }

                    if(this.check_dates_patient()) {
                        const message = "Il paziente ha già un appuntamento nella data selezionata"

                        this.validation.conflict = message
                        this.disabled = 1
                    } else {
                        this.validation.conflict = false
                        this.disabled = 0                                
                    }                     
                } catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })


                }
            },

            check_dates_patient(){
                // Parametri selezionati
                var {
                    date
                } = this.form.appointment


                for(const appointment of this.appointments_patient.DB) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")

                    date = date.replaceAll('-', '');
                    date_a = date_a.replaceAll('-', '');

                    const conflitto = (
                        date_a == date
                    )          

                    if(conflitto) return true
                }    

            },
          
            check_last_appointment() {

                var dates=[];

                var dateForm = this.form.appointment.date              
                var maxDate;
                for(const appointment of this.getEventsByRoomSelected(false)) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")
                    var h_end_a   = moment(appointment.h_end).format("HH:mm")

                    if(dateForm == date_a){
                        dates.push(new Date(date_a + ' ' + h_end_a))
                    }
                    
                }

                if(dates.length > 0){
                    maxDate = new Date(Math.max.apply(null, dates));  
                    maxDate = new Date(maxDate)
                    return maxDate;
                } else {
                    return null;
                }
                

            },

            check_max_visits_for_room() {
                var flag = 0 
                var check = 0

                for(const appointment of this.getEventsByRoomSelected(false)) {   

                    // Parametri appuntamento
                    const date_a    = moment(appointment.date).format("YYYY-MM-DD")
                    let date_sel = this.form.appointment.date 

                    if(date_a == date_sel){                       
                        this.rooms.DB.find(
                            room => {                               
                                if(room.id == this.form.appointment.id_room) {
                                    flag++
                                    let max_visits = room.max_visits;      
                                    if(flag >= max_visits){   
                                        check = 1                           
                                        flag = 0                                       
                                    } else {
                                        check = 0  
                                    }                                
                                }

                            }
                        )    
                    }   

                    if(check == 1) return true
                    
                }


            },            
            format_event_for_calendar(appointment) {
                var title_therapy;
                for(const therapy of this.therapies.DB) {
                    if(therapy.id == appointment.patient_pathology.id_therapy) {
                        title_therapy = therapy.title
                    } else if(!appointment.patient_pathology.id_therapy){
                        title_therapy = 'Nessuna terapia'
                    }
                }
       
                return {
                    start: `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_start).format('HH:mm')}`,
                    end:   `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_end).format('HH:mm')}`,
                    title:  `
                        <span
                            class= "f-size-1-em"
                            title= "${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')}\n${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name}\n Terapia: ${title_therapy}">
                            ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name} -  ${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')} - Terapia:  ${title_therapy}
                        </span>                        
                        `,
                    class: 'color-event'
                }
            },          
            easterDateLLongre(Y) {
                let M=3, G= Y % 19+1, C= ~~(Y/100)+1, L=~~((3*C)/4)-12,
                E=(11*G+20+ ~~((8*C+5)/25)-5-L)%30, D;
                E<0 && (E+=30);
                (E==25 && G>11 || E==24) && E++;
                (D=44-E)<21 && (D+=30);
                (D+=7-(~~((5*Y)/4)-L-10+D)%7)>31 && (D-=31,M=4);

                if(D == 31){
                    D = 1;
                    M = M+1
                } else {
                    D = D+1;
                }  

                if(M < '10'){ 
                    M = '0' + M;
                } 
  
                if(D < '10'){ 
                    D = '0' + D;
                }                 
                return Y + '-' + M + '-' + D;
            }, 
            format_room_for_calendar_holidays(arrHoliday) {
                listHoliday = []
                for (let index = 0; index < arrHoliday.length; ++index) {
                    const element = Object.keys(arrHoliday[index]);
                    
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();
                    listHoliday.push({
                        [day] : {
                            date: element,                       
                            from: {'am': 8 * 60, 'pm': 13 * 60},
                            to: {'am': 13 * 60, 'pm': 20 * 60},
                            class: 'doctor-3',
                            label: 'Festivo'                               
                        }                                     
                    });                                                                   
                }  


                list2Holiday = Object.assign({}, listHoliday);  
                return list2Holiday;
                    
            },                               
            format_room_for_calendar(room) {
                arr = [];
                objroom.push(room);

                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  

                var data;
                var from = '00:00';
                var to = '00:00';
                var frompm = '00:00';
                var topm = '00:00';               
                for (let index = 0; index < objroom.length; ++index) {
                    startDate = new Date(objroom[index].dal);
                    endDate = new Date(objroom[index].al);
                  
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dateFormat = new Date(format4);
                        var dayweek = dateFormat.getDay();

                        if(dayweek == '1'){
                            from = objroom[index].monday_1.am.dal;
                            to = objroom[index].monday_1.am.al;
                            frompm = objroom[index].monday_1.pm.dal;
                            topm = objroom[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroom[index].tuesday_2.am.dal;
                            to = objroom[index].tuesday_2.am.al;       
                            frompm = objroom[index].tuesday_2.pm.dal;
                            topm = objroom[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroom[index].wednesday_3.am.dal;
                            to = objroom[index].wednesday_3.am.al;     
                            frompm = objroom[index].wednesday_3.pm.dal;
                            topm = objroom[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroom[index].thursday_4.am.dal;
                            to = objroom[index].thursday_4.am.al; 
                            frompm = objroom[index].thursday_4.pm.dal;
                            topm = objroom[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroom[index].friday_5.am.dal;
                            to = objroom[index].friday_5.am.al;         
                            frompm = objroom[index].friday_5.pm.dal;
                            topm = objroom[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            
                            from = objroom[index].saturday_6.am.dal;
                            to = objroom[index].saturday_6.am.al;   
                            frompm = objroom[index].saturday_6.pm.dal;
                            topm = objroom[index].saturday_6.pm.al; 
                                    
                        } 
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arr.push(data);                        
                    }                                                 
                }   
                
                for (let index = 0; index < arr.length; ++index) {
                    const element = Object.keys(arr[index]);
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();

                    
                    var fromMor = String(arr[index][element].am.from);                   
                    var toMor = String(arr[index][element].am.to);

                    var fromMorS = fromMor.split(':');                  
                    var fromMorH = fromMorS[0];                    
                    var fromMorM = fromMorS[1];
                    var minutesMor = 0;

                    if(fromMorM != '00'){
                        if(fromMorM >= '10' && fromMorM <= '25'){ 
                            minutesMor = '2';
                        } else if(fromMorM >= '26' && fromMorM <= '35'){
                            minutesMor = '5';
                        } else if(fromMorM >= '36' && fromMorM <= '59'){
                            minutesMor = '7';
                        }     
                    } 
                    var fromMorF = parseInt(fromMorH) + '.'+ minutesMor;

                    var toMorS = toMor.split(':');                  
                    var toMorH = toMorS[0];                    
                    var toMorM = toMorS[1];
                    var tominutesMor = 0;

                    if(toMorM != '00'){
                        if(toMorM >= '10' && toMorM <= '25'){ 
                            tominutesMor = '2';
                        } else if(toMorM >= '26' && toMorM <= '35'){
                            tominutesMor = '5';
                        } else if(toMorM >= '36' && toMorM <= '59'){
                            tominutesMor = '7';
                        }     
                    } 

                    var toMorF = parseInt(toMorH) + '.'+ tominutesMor;   

                    //afternoon
                    var fromAft = String(arr[index][element].pm.from);                   
                    var toAft = String(arr[index][element].pm.to);

                    var fromAftS = fromAft.split(':');                  
                    var fromAftH = fromAftS[0];                    
                    var fromAftM = fromAftS[1];
                    var minutesAft = 0;

                    if(fromAftM != '00'){
                        if(fromAftM >= '10' && fromAftM <= '25'){ 
                            minutesAft = '2';
                        } else if(fromAftM >= '26' && fromAftM <= '35'){
                            minutesAft = '5';
                        } else if(fromAftM >= '36' && fromAftM <= '59'){
                            minutesAft = '7';
                        }     
                    } 
                    var fromAftF = (parseInt(fromAftH) + '.'+ minutesAft);

                    

                    var toAftS = toAft.split(':');   
             
                    var toAftH = toAftS[0];                    
                    var toAftM = toAftS[1];
                    var tominutesAft = 0;

                    if(toAftM != '00'){
                        if(toAftM >= '10' && toAftM <= '25'){ 
                            tominutesAft = '2';
                        } else if(toAftM >= '26' && toAftM <= '35'){
                            tominutesAft = '5';
                        } else if(toAftM >= '36' && toAftM <= '59'){
                            tominutesAft = '7';
                        }     
                    } 

                    var toAftF = parseInt(toAftH) + '.'+ tominutesAft;   
 
                    
                    if(day == 0){
                        list.push({
                            [7] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    } 
                    if(day == 6){
                        list.push({
                            [6] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    }     
                                  
                    let year = this.yearCal

                    let easter = this.easterDateLLongre(year);

                    var days_holiday = [year + "-01-01", year + "-01-06", year + "-04-25", year + "-05-01", year + "-06-02", year + "-06-29", year + "-08-15", year + "-11-01", year + "-12-08", year + "-12-25", year + "-12-26", easter];
                    
                    let dayHol = ('0' + dateNew.getDate()).slice(-2);       
                    let monthHol = ('0' + (dateNew.getMonth()+1)).slice(-2);        
                    let yearHol = dateNew.getFullYear();  
                    let format4Hol = yearHol + "-" + monthHol + "-" + dayHol;

                    if(days_holiday.includes(format4Hol)){
                        list.push({
                            [day] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                               
                            }                                     
                        }); 
                    } else {
                        if(fromMorH != '' || fromAftH != ''){               
                            list.push({
                                [day] : {
                                    date: element,                       
                                    from: {'am': fromMorF * 60, 'pm': fromAftF * 60},
                                    to: {'am': toMorF * 60, 'pm': toAftF * 60},
                                    class: 'doctor-1',
                                    label: 'Disponibile'                               
                                }                                     
                            }); 
                        } 
                    }                                        
                }  
                
                list2 = Object.assign({}, list);  
                return list2;
                      
            },
            getEventsByRoomSelected(for_calendar) {
                let appointments = []

                this.rooms.DB.find(
                    room => {

                        if(room.id == this.form.appointment.id_room) {
                            if(room.appointments){
                                for(const appointment of room.appointments) {  
                                    appointments.push(
                                        for_calendar ? this.format_event_for_calendar(appointment) : appointment
                                    )

                                }
                            }
                        }

                    }
                )

                return appointments

            }       

        },
        mounted() {
            this.readRooms()   
            this.readVisitTypes()
            this.readTherapies()
            this.read_durations()       
        },
        computed: {
            getRoomAvailibilities() {    
                let year = this.yearCal

                let easter = this.easterDateLLongre(year);
                
                var days_holiday = {
                    "0": year + "-01-01",
                    "1": year + "-01-06",
                    "2": year + "-04-25",
                    "3": year + "-05-01",
                    "4": year + "-06-02",
                    "5": year + "-06-29",
                    "6": year + "-08-15",
                    "7": year + "-11-01",
                    "8": year + "-12-08",
                    "9": year + "-12-25",
                    "10": year + "-12-26",
                    "11": easter 
                };  

                days_holiday = JSON.parse(JSON.stringify(days_holiday));
                myString = []
                availibilities = []                       
                Object.keys(days_holiday).forEach(key => {
                    days_holiday_obj = {
                        [days_holiday[key]] : {
                            'am':{'to':['20:00'],'from':['08:00']},'pm':{'to':['20:00'],'from':['08:00']}
                        }
                    } 
                    myString.push(days_holiday_obj);                        
                }); 
                availibilities.push(
                    this.format_room_for_calendar_holidays(myString)
                ) 
            
                if(this.room.DB.length !== 0){  
                    var room = JSON.parse(JSON.stringify(this.room.DB));
                    for(const roomAv of room) {   
                        //console.log('ggg ' + roomAv);                                                               
                        availibilities.push(
                            this.format_room_for_calendar(roomAv)
                        )                        
                    }     
                }

                newObj = availibilities.reduce((a, b) => Object.assign(a, b), {}) 
                return newObj;   

            },    
            therapies_by_pathology() {

                let therapies = []

                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                        if(patient_pathology.pathology.pathologies_therapies) therapies = patient_pathology.pathology.pathologies_therapies
                    }
                }

                return therapies

            },
            therapy_cronology() {

                let cronology = []

                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                        if(patient_pathology.pathology.pathologies_therapies) cronology = patient_pathology.therapy_cronologies
                    }
                }

                //console.log("this.patients_pathologies", this.patients_pathologies)

                return cronology

            }
        },
        watch: {    
            getRoomAvailibilities: {
                deep: true,
                handler: function (newVal) {  
    
                    if(Object.keys(newVal).length == 12){
                        this.classCal = 1
                        newVal = []
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                         
                        availibilities = [];
                        newObj = {};   
                        days_holiday_obj = {} ; 
                        listHoliday = [];
                        list2Holiday = {}; 
                        myString = []; 
                        objroomRoomAvailable = [];
                        arrRoomAvailable = [];  
                        objroomMaxVisit = [];
                        arrMaxVisit = [];                                                                                          
                    } else {
                        this.classCal = 2
                        newVal = []
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                         
                        availibilities = [];
                        newObj = {};   
                        days_holiday_obj = {} ; 
                        listHoliday = [];
                        list2Holiday = {}; 
                        myString = []; 
                        objroomRoomAvailable = [];
                        arrRoomAvailable = [];
                        objroomMaxVisit = [];
                        arrMaxVisit = [];                                                                                                     
                    }
                }
            }
        }       
    }

</script>