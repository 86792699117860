<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">poltrone/durata/modifica</li>
                            </ol>
                        </nav>
                    </div>

                    <div class="card card-body mb-3">
                        <!--@submit.prevent="updateRoom"  -->
                        <form autocomplete="off">

                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="form-group">
                                        <label for="title">Titolo</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required disabled>                           
                                        </div>
                                        <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                            <small>
                                                Titolo richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="title">Durata (in minuti)</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" id="duration" @keyup="onlyNumeric" v-model.number="$v.form.duration.$model" v-bind:class="{'is-invalid': !$v.form.duration.required && $v.form.duration.$dirty}" required>                           
                                        </div>
                                        <p v-if="!$v.form.duration.required && $v.form.duration.$dirty" class="m-0 text-danger">
                                            <small>
                                                Durata richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div>                         
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="description">Descrizione</label>
                                        <div class="input-group">
                                            <textarea class="form-control " id="description" v-model.trim="$v.form.description.$model"></textarea>                           
                                        </div>
                                    </div>
                                </div>
                            </div>                  

                            <div v-show="validation.ko" class="alert alert-danger text-center">
                                {{validation.ko}}
                            </div>
                            <!--button  || !$v.$anyDirty  -->    
                            <button v-if="!functionDisabled(14, 'update')" @click="updateDuration" class="btn btn-ifo" :disabled="$v.$invalid || loaders.updating">
                                    
                                <span v-show="!loaders.updating">
                                    Modifica
                                </span>

                                <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                            </button>

                        </form>

                    </div>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(14, 'delete')}" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            <span class="d-none d-md-block">Elimina</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa durata?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="deleteDuration" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="nav-item">
                        <a @click="suspendDuration" v-bind:class="{'disabled': loaders.suspending || functionDisabled(14, 'update')}" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block" v-if="!loaders.suspending && form.state">Rendi non visibile</span> <svg v-show="!loaders.suspending && form.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-md-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                            </svg>
                        
                            <span class="d-none d-md-block" v-if="!loaders.suspending && !form.state">Rendi visibile</span> <svg v-show="!loaders.suspending && !form.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-md-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                            </svg>

                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import store  from "@/store"

    export default {
        data() {
            return {
                form: {
                    title:       null,
                    duration:    null,
                    description: null                      
                },
                validation: {
                    ko: false,
                    conflict:  false
                },
                loaders: {
                    reading:    true,
                    updating:   false,
                    deleting:   false,
                    suspending: false
                }                            
            }
        },
        validations() {
            return {
                form: {
                    title: {
                        required
                    },
                    duration: {
                        required
                    },               
                    description: {

                    }                               
                }
            }
        },
        methods: {
            onlyNumeric(e) {
                e.target.value = e.target.value.replace(/[^0-9]+/g, '')
            } ,                                                                                          
            async updateDuration() {

                this.loaders.updating = true

                try {

                    const duration = await this.axios.post(`user/chairs_duration/update`, this.form,
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: duration.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating = false
                }
                
                
            },
            async readDuration() {

                try {

                    const duration = await this.axios.get(`user/chairs_duration/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form = duration.data
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async deleteDuration() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/chairs_duration/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/poltrone/duration`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }

            },
            async suspendDuration() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/chairs_duration/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.form.state = !this.form.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readDuration()
        }
    }

</script>