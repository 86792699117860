<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading.pathologies" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="card card-body mb-2">

                                    <h5 class="font-weight-bold m-0">Modifica l'utente</h5>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Informazioni dettagliate sull'utente e sulle sue attività nel software
                                        </small>
                                    </p>

                                    <div>
                                        <div class="badge badge-danger text-truncate" v-show="!form.user.password">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock" viewBox="0 0 16 16">
                                                <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                            </svg> Non confermato
                                        </div>
                                        <div class="badge badge-success text-truncate ml-1" v-show="form.user.status">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                            </svg> Attivo
                                        </div>
                                        <div class="badge badge-danger text-truncate ml-1" v-show="!form.user.status">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                            </svg> Bloccato
                                        </div>
                                    </div>

                                    <form @submit.prevent="updateUser" autocomplete="off">

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group">

                                                    <label for="username">Username</label>

                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="username" v-model="form.user.username" disabled>                           
                                                    </div>

                                                    <p>
                                                        <small class="text-warning">
                                                            Non puoi modificare l'username utente
                                                        </small>
                                                    </p>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="name">Nome</label>

                                                    <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.user.name.$model" v-bind:class="{'is-invalid': !$v.form.user.name.required && $v.form.user.name.$dirty}">
                                                        
                                                    <p v-if="!$v.form.user.name.required && $v.form.user.name.$dirty" class="m-0 text-danger">
                                                        <small>Nome richiesto</small>
                                                    </p>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="surname">Cognome</label>

                                                    <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.user.surname.$model" v-bind:class="{'is-invalid': !$v.form.user.surname.required && $v.form.user.surname.$dirty}">
                                                    
                                                    <p v-if="!$v.form.user.surname.required && $v.form.user.surname.$dirty" class="m-0 text-danger">
                                                        <small>Cognome richiesto</small>
                                                    </p>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="email">Email</label>

                                                    <input type="email" class="form-control text-lowercase" id="email" v-model.trim="$v.form.user.email.$model" v-bind:class="{'is-invalid': (!$v.form.user.email.required || !$v.form.user.email.email) && $v.form.user.email.$dirty}">
                                                    
                                                    <p v-if="!$v.form.user.email.required && $v.form.user.email.$dirty" class="m-0 text-danger">
                                                        <small>Email richiesta</small>
                                                    </p>
                                                    <p v-if="!$v.form.user.email.email && $v.form.user.email.$dirty" class="m-0 text-danger">
                                                        <small>Email errata</small>
                                                    </p>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="phone">Cellulare</label>

                                                    <input type="phone" class="form-control" id="phone" v-model.trim="$v.form.user.phone.$model" v-bind:class="{'is-invalid': (!$v.form.user.phone.numeric) && $v.form.user.phone.$dirty}">
                                                    
                                                    <p v-if="!$v.form.user.phone.numeric && $v.form.user.phone.$dirty" class="m-0 text-danger">
                                                        <small>Non puoi inserire lettere nel numero</small>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="pathology_competence">Patologia di competenza</label>

                                                    <select class="form-control" id="pathology_competence" v-model="form.user.id_pathology_competence">

                                                        <option selected value="null">Nessuna patologia</option>

                                                        <option v-for="(pathology, index) of DB.pathologies" v-bind:key="index" v-bind:value="pathology.id">
                                                            {{pathology.title}}
                                                        </option>

                                                    </select>

                                                    <p class="m-0 text-muted">
                                                        <small>Associa l'utente ad una patologia, per personalizzare l'esperienza nel software</small>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <p class="font-weight-bold m-0">Tipologia</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Seleziona una tipologia di utenza che verrà ricoperta,<br>ricorda che ciascuna ha dei privilegi specifici
                                            </small>
                                        </p>

                                        <div class="form-group mb-0" v-for="(userType, index) of DB.userTypes" :key="`user_type_${index}`">
                                            <div class="custom-control custom-radio">

                                                <input type="radio" :id="`user_type_${index}`" :value="userType.id" name="userType" class="custom-control-input" v-model="form.user.id_user_type" required>

                                                <label class="custom-control-label" :for="`user_type_${index}`">
                                                    {{userType.title}}
                                                </label>
                                                
                                            </div>
                                        </div>

                                        <div v-show="validation.ko" class="alert alert-danger">
                                            {{validation.ko}}
                                        </div>

                                        <button v-if="!functionDisabled(5, 'update')" @click="updateUser" class="btn btn-ifo mt-3" :disabled="$v.$invalid || loaders.updating">
                                                
                                            <span v-show="!loaders.updating">
                                                Modifica
                                            </span>

                                            <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                        </button>

                                    </form>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card card-body mb-2">

                                    <div class="text-center">

                                        <h5 class="font-weight-bold m-0">Appuntamenti</h5>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Quantità di appuntamenti creati
                                            </small>
                                        </p>

                                        <h5 class="font-weight-bold f-family-monospace m-0 f-size-2-em">{{form.user.appointments != null ? form.user.appointments.length : 0}}</h5>

                                    </div>

                                    <div v-for="(appointment, key) of appointments" :key="key" class="pb-1" v-bind:class="{'border-bottom': key != appointments.length-1}">
                                        <p class="m-0 text-truncate">
                                            <small class="font-weight-bold">
                                                {{appointment.date | data_slash}}
                                            </small>
                                            <small class="text-muted">
                                                {{appointment.patient_pathology.patient.name}} {{appointment.patient_pathology.patient.surname}}
                                            </small>
                                        </p>
                                    </div>
                                    <p class="m-0" v-show="form.user.appointments != null">
                                        <small class="text-muted">Altri {{form.user.appointments != null ? form.user.appointments.length - appointments.length : ""}}</small>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-if="!loaders.sending.email" href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" @click="sendEmailSetPassword" v-bind:class="{'disabled': form.user.password || buttons.disabled.sendMail}">
                            Imposta password <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send ml-2" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                            </svg>
                        </a>
                        <a v-else class="nav-link d-flex align-items-center justify-content-center p-3 a_bg-ifo border-bottom disabled">
                            <div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" @click="sendEmailResetPassword" v-bind:class="{'disabled': !form.user.password || loaders.sending.email_reset_pw}">
                            
                            <span v-show="!loaders.sending.email_reset_pw">
                                Reset password <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock ml-2" viewBox="0 0 16 16">
                                    <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                </svg>
                            </span>

                            <div v-show="loaders.sending.email_reset_pw" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                    <li class="nav-item">
                        <a @click="change_status_user" v-bind:class="{'disabled': loaders.blocking || functionDisabled(5, 'update')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            
                            <span v-show="!loaders.blocking && form.user.status">
                                Blocca utente <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                </svg>
                            </span>
                            <span v-show="!loaders.blocking && !form.user.status">
                                Sblocca utente <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                </svg>
                            </span>

                            <div v-show="loaders.blocking" class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">
                                    Loading...
                                </span>
                            </div>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        email,
        numeric
    } from "vuelidate/lib/validators"

    import store  from '@/store'

    export default {
        data() {
            return {
                form: {
                    user: {
                        name:                    null,
                        surname:                 null,
                        phone:                   null,
                        email:                   null,
                        id_user_type:            null,
                        id_pathology_competence: null,
                        appointments: []
                    }
                },
                validation: {
                    ko: false
                },
                loaders: {
                    updating: false,
                    reading: {
                        pathologies: true
                    },
                    sending: {
                        email: false,
                        email_reset_pw: false
                    },
                    blocking: false
                },
                DB: {
                    userTypes:   [],
                    pathologies: []
                },
                buttons: {
                    disabled: {
                        sendMail: false
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    user: {
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        email: {
                            required,
                            email
                        },
                        phone: {
                            numeric
                        }
                    }
                }
            }
        },
        methods: {
            async readUser() {

                try {

                    let user = await this.axios.get(`user/users/read/detail/${this.$route.params.username}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.user = user.data[0]
        
                    this.DB.userTypes.push(user.data[0].user_type)


                    this.readUserTypes()
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                        type:    `error`
                    })

                    this.$router.push(`/user/utenti`)

                }

            },
            async updateUser() {

                this.loaders.updating = true

                try {

                    const user = await this.axios.post(`user/users/update`, this.form.user,
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: user.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/utenti`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                        type:    `error`
                    })

                }
                finally {
                    this.loaders.updating = false
                }
            },
            async readUserTypes() {

                const where = {
                    NOT: [{
                        id: this.form.user.id_user_type
                    }]
                }

                try {

                    const userTypes = await this.axios.post(`user/user_types/read/search`, {where}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    for(let userType of userTypes.data) {
                        this.DB.userTypes.push(userType)
                    }

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                        type:    `error`
                    })

                    this.$router.push(`/user/utenti`)

                }

            },
            async readPathologies() {
                
                try {

                    const pathologies = await this.axios.get(`user/pathologies/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    this.DB.pathologies = pathologies.data

                }
                catch(e) {
                    this.$toast.open({
                        message: `Si è verificato un problema durante la ricerca delle patologie da associare all'utente, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                        type:    `error`
                    })
                }
                finally {
                    this.loaders.reading.pathologies = false
                }

            },
            async sendEmailSetPassword() {
                
                this.loaders.sending.email = true

                try {

                    const sended = await this.axios.post(`auth/password/sendMailSetPassword`, this.form.user, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.buttons.disabled.sendMail = true
                    
                    this.$toast.open({
                        message: sended.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {
                    this.$toast.open({
                        message: `Si è verificato un problema durante l'invio della mail', ${e.response ? e.response.data.msg : null}`,
                        type:    `error`
                    })
                }
                finally {
                    this.loaders.sending.email = false
                }
            },
            async sendEmailResetPassword() {
                
                this.loaders.sending.email_reset_pw = true

                try {

                    const sended = await this.axios.post(`auth/password/update/sendReset`, {email: this.form.user.email}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    this.$toast.open({
                        message: sended.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {
                    this.$toast.open({
                        message: `Si è verificato un problema durante l'invio della mail', ${e.response ? e.response.data.msg : null}`,
                        type:    `error`
                    })
                }
                finally {
                    this.loaders.sending.email_reset_pw = false
                }
            },
            async change_status_user() {

                this.loaders.blocking = true

                try {

                    const res = await this.axios.post(
                        "user/users/block_user", {
                            username: this.$route.params.username
                        }, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: res.data.msg,
                        type:   "success"
                    })

                    this.form.user.status = !this.form.user.status
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.blocking = false
                }

            },

            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readUser()
            this.readPathologies()
        },
        computed: {
            appointments() {

                let OBJ = []

                for(let key in this.form.user.appointments) {
                    if(key < 3) OBJ.push(this.form.user.appointments[key])
                }

                return OBJ

            },
            pathologies() {

                let OBJ = []

                for(let key in this.form.user.pathologies) {
                    if(key < 3) OBJ.push(this.form.user.pathologies[key])
                }

                return OBJ

            }
        }
    }

</script>