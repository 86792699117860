<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-md-10 py-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />
                
                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi una nuova poltrona</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Ad ogni poltrona è possibile assegnare un numero
                        </small>
                    </p>
                    <!-- @submit.prevent="createChair" -->
                    <form  autocomplete="off">

                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group">

                                    <label for="id_pathology_type">Durata</label>

                                    <select class="form-control" id="id_duration" @change="onChangeDuration($event.target.value)" v-model="DB.selected_duration">
                                        <option value="0">Seleziona</option>
                                        <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                            {{duration.title}} - {{duration.duration}} min.
                                        </option>
                                    </select>

                                    <small class="form-text text-muted">
                                    </small>

                                </div>
                            </div>  
                            <!--<div class="col-lg-2">
                                <div class="form-group">

                                    <label for="type_patient">Tipo paziente</label>

                                    <select class="form-control" id="type_patient" v-model="form.type_patient">
                                        <option :value="0">
                                            TUTTI
                                        </option>                                        
                                        <option :value="1">
                                            DI ROMA
                                        </option>
                                        <option :value="2">
                                            FUORI ROMA
                                        </option>                                       
                                    </select>

                                    <small class="form-text text-muted">
                                    </small>

                                </div>
                            </div> -->                                                         
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="title">Titolo</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control text-uppercase" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                        <small>
                                            Titolo richiesto
                                        </small>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="title">Numero</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control text-uppercase" maxlength="3" id="number" min="0" v-model.number="$v.form.number.$model" v-bind:class="{'is-invalid': !$v.form.number.required && $v.form.number.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.number.required && $v.form.number.$dirty" class="m-0 text-danger">
                                        <small>
                                            Numero richiesto
                                        </small>
                                    </p>                                   
                                </div>
                            </div>                                             
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="description">Descrizione</label>
                                    <div class="input-group">
                                        <textarea class="form-control " id="description" v-model="form.description"></textarea>                           
                                    </div>
                                </div>
                            </div> 
                        </div>  
              
                        <div v-show="validation.ko" class="alert alert-danger text-center">
                            {{validation.ko}}
                        </div>

                        <button @click="createChair" class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                
                            <span v-show="!loaders.creating">
                                Aggiungi
                            </span>

                            <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>

                </div>
                
            </div>
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import moment from "moment"

    export default {
        data() {            
            return {
                DB: {
                    durations: [],
                    selected_duration: 0
                },                
                form: {
                    title:              null,
                    number:             null,
                    description:        null,
                    id_chair_duration:  0,
                    type_patient:       0
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false
                },
                utility: {
                    today: moment().format("YYYY-MM-DD")
                },
                calendar: {
                    config: {
                        minDate:      moment().format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     30,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [],
                        selectedDate: null,
                        specialHours: null
                    }
                }                              
            }
        },
        validations() {
            return {
                form: {
                    title: {
                        required
                    },                    
                    number: {
                        required
                    },
                    id_chair_duration: {
                        required
                    }                              
                }
            }
        },
        methods: { 
            onChangeDuration(event) {
                this.form.id_chair_duration = event
            },              
            async read_durations() {
                try {

                    this.DB.durations = (
                        await this.axios.get(`user/chairs_duration/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },                                                    
            async createChair() {
                this.loaders.creating = true
                this.validation.ko    = false      

                try {
                    const res = await this.axios.post(`user/chairs/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                
                    this.$router.push(`/user/poltrone`)
      
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            }
        },
        mounted(){
            this.read_durations();
        }
    }
</script>