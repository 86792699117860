<template>
  <div class="container-fluid bg-linear_1">

    <div class="p-4">

      <p class="m-0 font-weight-bold f-size-1_4-em letter-spacing_1px">
        Oncoga DH1
      </p>

    </div>

    <div class="row h-main_home-lg">

        <div class="col-md-6 col-lg-8 d-flex flex-column justify-content-center align-items-center p-5 pl-lg-0">
          <img src="../assets/ifo_logo.png" alt="logo" class="w-75 w-100-md w-lg-50 w-xl-25">
          <img src="../assets/ifo_logo_2.png" alt="logo" class="w-75 w-100-md w-lg-50 w-xl-25 mt-4">
        </div>

        <div class="col-md-6 col-lg-4 offset-lg-0 d-flex flex-column justify-content-md-center justify-content-start p-5 p-lg-0">

          <h2 class="f-size-2_5-em font-weight-bold m-0">
            Benvenuto
          </h2>
          <h3 class="f-size-0_8-em mb-3">
            Accedi per iniziare a lavorare
          </h3>

          <form @submit.prevent="doLogin" autocomplete="off" class="w-lg-50">
            <div class="form-group mb-2">

              <input type="text" class="form-control" placeholder="username" v-model.trim="$v.form.username.$model" v-bind:class="{'is-invalid': !$v.form.username.required && $v.form.username.$dirty}" required>

              <p v-if="!$v.form.username.required && $v.form.username.$dirty" class="m-0 text-danger">
                <small>
                  Username richiesto
                </small>
              </p>
              
            </div>
            <div class="form-group mb-2">

              <div class="input-group">

                  <input :type="utilities.show.password ? 'text' : 'password'" class="form-control" id="password" placeholder="password" v-model.trim="$v.form.password.$model" v-bind:class="{'is-invalid': (!$v.form.password.required && $v.form.password.$dirty)}">
                  
                  <div class="input-group-append">
                      <button class="btn btn-ifo d-flex" type="button" @click="utilities.show.password =! utilities.show.password">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-eye m-auto" viewBox="0 0 16 16">
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                          </svg>
                      </button>
                  </div>

              </div>

              <p v-if="!$v.form.password.required && $v.form.password.$dirty" class="m-0 text-danger">
                <small>
                  Password richiesta
                </small>
              </p>

            </div>

            <div v-show="validation.ko" class="alert alert-danger text-center mb-2">
              {{validation.ko}}
            </div>

            <div class="row">

              <div class="col-xl-6">
            
                <button @click="doLogin" class="btn btn-ifo w-100 text-center" :disabled="!$v.form.username.required || !$v.form.password.required || loaders.isLogging">
                  
                  <span v-show="!loaders.isLogging">
                    Accedi <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-box-arrow-in-right ml-2" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                      <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                    </svg>
                  </span>

                  <div v-show="loaders.isLogging" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                </button>

              </div>

              <div class="col-xl-6 d-flex">

                <a class="mb-auto" v-b-toggle.formSendPassword>
                  <small>
                    Non ricordi la password?
                  </small>
                </a>

              </div>

            </div>

            <b-collapse id="formSendPassword" class="mt-1">

              <form @submit.prevent="resetPassword" autocomplete="off">
                <div class="form-group">

                  <div class="input-group mb-2">

                      <input type="email" class="form-control text-lowercase" v-model.trim="$v.form_pw.email.$model" placeholder="Digita la tua email di registrazione.." v-bind:class="{'is-invalid': (!$v.form_pw.email.required || !$v.form_pw.email.email) && $v.form_pw.email.$dirty || validation_pw.ko, 'is-valid': validation_pw.ok}">
                      
                      <div class="input-group-append">
                          <button class="btn btn-ifo d-flex" :disabled="!$v.form_pw.email.required || !$v.form_pw.email.email || validation_pw.ok || loaders.sendingMail">

                              <svg v-show="!loaders.sendingMail" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-send m-auto" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                              </svg>

                              <div v-show="loaders.sendingMail" class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>

                          </button>
                      </div>

                  </div>

                  <p v-if="!$v.form_pw.email.required && $v.form_pw.email.$dirty" class="m-0 text-danger">
                      <small>Email richiesta</small>
                  </p>
                  <p v-if="!$v.form_pw.email.email && $v.form.password.$dirty" class="m-0 text-danger">
                      <small>Email errata</small>
                  </p>
                  <div v-show="validation_pw.ko" class="alert alert-danger text-center">
                    {{validation_pw.ko}}
                  </div>
                  <div v-show="validation_pw.ok" class="alert alert-info text-center">
                    {{validation_pw.ok}}
                  </div>

                </div>
              </form>
              
            </b-collapse>

          </form>

        </div>

    </div>

    <div class="text-center p-5 p-lg-0">

      <p class="m-0 f-size-0_8-em">Istituti Fisioterapici Ospitalieri (IFO) Via Chianesi 53 00144 Roma Partita IVA 01033011006</p>
      <p class="m-0 f-size-0_8-em">Centralino +39 06 52661 Pronto Informazioni +39 06 52665046</p>
      <p class="m-0 f-size-0_8-em">
        <small>KG Partners</small>
      </p>

    </div>

  </div>
</template>

<script>

  import { 
    required,
    email
  } from "vuelidate/lib/validators"

  import { 
    mapActions
  } from "vuex"

  export default {
    data() {
      return {
        form: {
          username: ``,
          password: ``
        },
        validation: {
          ko: false
        },
        loaders: {
          isLogging: false,
          sendingMail: false
        },
        form_pw: {
          email: null,
        },
        validation_pw: {
          ko: false,
          ok: false
        },
        utilities: {
          show: {
            password: false
          }
        },
      }
    },
    validations: {
      form: {
        username: {
          required
        },
        password: {
          required
        }
      },
      form_pw: {
        email: {
          required,
          email
        }
      }
    },
    methods: {

      ...mapActions(["doCentralLogin"]),

      async doLogin() {

        this.loaders.isLogging = true
        this.validation_pw.ko  = false
        
        try {

          await this.doCentralLogin(this.form)

          this.$router.push(`/user/agenda`)

        }
        catch(e) {
          console.log(`doLogin da view ERRORE`, e)
          this.validation.ko = e.response.data.msg
        }
        finally {
          this.loaders.isLogging = false
        }

      },
      async resetPassword() {

        this.loaders.sendingMail = true
        this.validation_pw.ok    = null

        const {
          email
        } = this.form_pw

        try {

            const res = await this.axios.post(`auth/password/update/sendReset`, {email}, {
                headers:{
                  Authorization: `bearer ${this.$store.state.auth.token}`
                }
            })

            const {
              msg
            } = res.data

            this.$toast.open({
                message: msg,
                type:   `success`
            })

            this.validation_pw.ok = msg

        }
        catch(e) {

            const message = `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`

            this.$toast.open({
                message,
                type: `error`
            })

            this.validation_pw.ko = message
            
        }
        finally {
            this.loaders.sendingMail = false
        }

      }

    }
  }

</script>